import * as React from "react";
import { ICommonData } from "../../lib/types/common";
import { IHomePageLanguageData } from "../../lib/types/home";
import { IGetStaticPageDataReturnProps } from "../../lib/types/Misc.type";
import {
  FIXED_DOWNLOAD_BUTTON_TYPE,
  GET_APP_KWAI_URL,
  GET_APP_PLAY_URL,
  GET_APP_REF_URL,
  GET_APP_TIKTOK_URL,
  HOME_PAGE_URL,
  VariantA,
} from "../../lib/utils/constants/GlobalConstants";
import {
  checkIsGetAppAF,
  getCountryFromLocale,
  getQueryStrings,
  isGetAppPage,
  isiOSDevice,
  isMobile,
} from "../../lib/utils/constants/PagesConstants";
import Head from "next/head";
import { IHomePageUSLanguageData } from "../../lib/types/home.us";
import {
  IAboutUsComponentProps,
  IBannerDownloadButtonComponentProps,
  IBannerNewComponentProps,
  ICommonWithdrawalPartnerComponentProps,
  IDownloadAppComponentProps,
  IFloatingDownloadButtonComponentProps,
  IFooterComponentProps,
  IFooterLinksSectionProps,
  IHeaderComponentProps,
  IHowToInstallAppComponentProps,
  IMemberOfComponentProps,
  IPageFAQComponentProps,
  IPopularGamesComponentProps,
  ISideDrawerComponentProps,
  IToastComponentProps,
  IUserRatingComponentProps,
  IWinzoBannerBottomComponentHomePageProps,
  IWinzoStoreOfferComponentProps,
  IWinzoTaglineProps,
  IWinzoUSPComponentProps,
  IWinzoVideoComponentProps,
  IWinzoWinnerComponentProps,
  IWithdrawalPartnersComponentProps,
} from "../../lib/types/components";
import AppsFlyer from "../../components/common/AppsFlyer";
import OfferPopupComponent from "../../components/common/OfferPopup/OfferPopupComponent";
import ToastComponent from "../../components/common/Toast/ToastComponent";
import { showHowToInstallStory, showHowToInstallVideo } from "../../lib/utils/helpers/helper";
import FloatingDownloadButtonComponentWithVideo
  from "../../components/common/FloatingDownloadButton/FloatingDownloadButtonComponentWithVideo";
import FloatingDownloadButtonComponentV2
  from "../../components/common/FloatingDownloadButton/FloatingDownloadButtonComponentV2";
import SideDrawerComponent from "../../components/common/SideDrawer/SideDrawerComponent";
import HeaderComponentV2 from "../../components/common/Header/HeaderComponentV2";
import BannerNewComponentV2 from "../../components/common/BannerNew/BannerNewComponentV2";
import BannerDownloadButtonComponentV2
  from "../../components/common/BannerDownloadButton/BannerDownloadButtonComponentV2";
import WinzoBannerBottomComponentHomePageV2
  from "../../components/common/WinzoBannerBottomHomePage/WinzoBannerBottomHomePageComponentV2";
import WinzoUSPComponentV2 from "../../components/common/WinzoUSP/WinzoUSPComponentV2";
import PopularGamesComponentV2 from "../../components/noJSComponents/PopularGames/PopularGamesComponentV2";
import WinzoWinnerComponentV2 from "../../components/noJSComponents/winzoWinner/WinzoWinnerComponentV2";
import UserRatingComponentV2 from "../../components/common/UserRatings/UserRatingComponentV2";
import AboutUsComponent from "../../components/noJSComponents/AboutUs/AboutUsComponent";
import WinzoStoreOfferComponentV2 from "../../components/noJSComponents/WinzoStoreOffer/WinzoStoreOfferComponentV2";
import WithdrawalPartnersComponentV2 from "../../components/common/WithdrawalPartners/WithdrawalPartnersComponentV2";
import MemberOfComponentV2 from "../../components/common/MemberOf/MemberOfComponentV2";
import WinzoVideoComponentV2 from "../../components/common/WinzoVideo/WinzoVideoComponentV2";
import AboutUsComponentV2 from "../../components/noJSComponents/AboutUs/AboutUsComponentV2";
import DownloadAppComponentV2 from "../../components/common/DownloadApp/DownloadAppComponentV2";
import PageFAQComponentV2 from "../../components/common/PageFAQ/PageFAQComponentV2";
import FooterLinksSectionV2 from "../../components/common/FooterLinks/FooterLinksSectionV2";
import FooterComponentV2 from "../../components/common/Footer/FooterComponentV2";
import HowToInstallAppComponentV2 from "../../components/noJSComponents/HowToInstallApp/HowToInstallAppComponentV2";
import CommonWithdrawalPartnerComponentV2
  from "../../components/noJSComponents/CommonWithdrawalPartner/CommonWithdrawalPartnerComponentV2";
import FloatingDownloadButtonComponentWithStory from "../../components/common/FloatingDownloadButton/FloatingDownloadButtonComponentWithStory";
import { HOW_TO_INSTALL_FLOW } from "../../lib/utils/enums/enums";
import { COUNTRY } from "../../lib/utils/constants/LocaleAndCountryConstants";

function IndexPageIndiaV2(
  props: IGetStaticPageDataReturnProps<IHomePageLanguageData>
) {
  const locale = props.apiData.locale;
  const pageName = props.apiData.pageName;
  const howToInstallVariant = props.apiData.howToInstallVariant;
  const showOfferPopup: boolean = props.apiData.showOfferPopup!;
  // All get-app pages boolean
  const isGetAppRef: boolean = pageName === GET_APP_REF_URL;
  const isGetApp: boolean = isGetAppPage(pageName);
  const isGetAppAf: boolean = checkIsGetAppAF(pageName);
  const isGetAppPlay: boolean = pageName === GET_APP_PLAY_URL;
  const isHomePage: boolean = pageName === HOME_PAGE_URL;
  const isGetAppKwai: boolean = pageName === GET_APP_KWAI_URL;
  const isGetAppTiktok: boolean = pageName === GET_APP_TIKTOK_URL;
  // variable containing api data from getStaticProps
  const content: IHomePageLanguageData | IHomePageUSLanguageData =
    props.apiData;
  const commonData: ICommonData = props.apiData.commonData as ICommonData;
  const selectLanguageText = props.apiData.commonData.selectLanguageText;
  //TODO : REMOVE THESE CHECKS AFTER text3 AND text4 ARE MOVED TO COMMON.
  if (commonData.text3) {
    content.bannerHome.text3 = commonData.text3;
  }
  if (commonData.text4) {
    content.bannerHome.text4 = commonData.text4;
  }

  const showHowToInstall = () => {
    if (isiOSDevice) {
      return false;
    }
    if ((isGetApp || isGetAppAf || isGetAppRef) && !isMobile) {
      return false;
    } else {
      return true;
    }
  };

  // All components' props' values assigned
  const toastCompProps: IToastComponentProps = {
    internetError: commonData?.toastComponent.internetError,
  };

  const floatingDownloadBtnCompProps: IFloatingDownloadButtonComponentProps = {
    type: FIXED_DOWNLOAD_BUTTON_TYPE,
    blinkTextDownload: commonData.blinkTextDownloadButton,
    blinkTextDownload2: commonData.blinkTextDownloadButton2,
    fixedDownloadButtonData: content.fixedDownloadButton || commonData.fixedDownloadButton,
    locale: locale,
    //TODO
    howToInstallVideo:
      content?.howToInstall?.howToInstallVideo||
      commonData?.howToInstall?.howToInstallVideo,
    howToInstallStory: content.howToInstallStory || commonData?.howToInstallStory,
    howToInstallThumbnail:
      content?.howToInstall?.howToInstallThumbnail||
      commonData?.howToInstall?.howToInstallThumbnail,
    downloadingAnimation: commonData.downloadingAnimation,
    isHomePage: isHomePage,
    pageName,
    // setAppsFlyerURL
  };

  const sideDrawerCompProps: ISideDrawerComponentProps = {
    navigationDataLinks: commonData.navigationDataLinks,
    navigationDataLinksV2: commonData.navigationDataLinksV2
  };
  const headerCompProps: IHeaderComponentProps = {
    specialData: commonData.specialData,
    hideHamburger: content.hideHamburger,
    fixedDownloadButtonData: content.fixedDownloadButton || commonData.fixedDownloadButton,
    languageToggleData: props.apiData.languageToggleData,
    locale: locale,
    countrySwitcherText: commonData.multiRegional?.countrySwitcherText,
    pageName,
    selectLanguageText,
  };
  const bannerNewCompProps: IBannerNewComponentProps = {
    bannerHomeData: content.bannerHome,
    smsSentModalData: commonData.smsSentModal,
    locale: locale,
    smsVariablesTemplate: commonData.smsVariablesTemplate,
    mobileNumber: commonData.mobileNumber,
    consent: commonData.consent,
    disableAppDownload: commonData.fixedDownloadButton.disabled || false,
    pageName,
    bannerMobilePlaystore: commonData.bannerMobilePlaystore,
    qrData: commonData.qrData,
  };
  const bannerDownloadBtnCompProps: IBannerDownloadButtonComponentProps = {
    fixedDownloadButtonData: content.fixedDownloadButton || commonData.fixedDownloadButton,
    blinkTextDownloadButton: commonData.blinkTextDownloadButton,
    iosCtaData: props.apiData?.iosCtaData,
    pageName,
  };
  const commonWithdrawalPartnerCompProps: ICommonWithdrawalPartnerComponentProps =
  {
    withdrawalPartnerData: commonData.withdrawalPartner,
    isInBanner: isMobile,
  };
  const winzoBannerBottomCompProps: IWinzoBannerBottomComponentHomePageProps = {
    bannerBottomData: commonData.bannerBottom,
    withdrawalPartnerData: commonData.withdrawalPartner,
  };
  const winzoTaglineProps: IWinzoTaglineProps = {
    WinzoTagLineData: content.WinzoTagLine,
    isWithdrawalPartnerData: !!commonData.withdrawalPartner,
  };
  const popularGamesCompProps: IPopularGamesComponentProps = {
    popularGamesData:
      content.popularGamesV2 || content.popularGames || undefined,
    isTextClickable: true,
    winzoTagLineProps: winzoTaglineProps,
  };
  const winzoWinnerCompProps: IWinzoWinnerComponentProps = {
    winzoWinnerData: commonData.winzoWinner,
  };
  const userRatingCompProps: IUserRatingComponentProps = {
    customerReviewData: commonData.customerReview,
    winzoWinnerCompProps: winzoWinnerCompProps,
  };
  const howToInstallCompProps: IHowToInstallAppComponentProps = {
    howToInstallData: commonData.howToInstall,
  };
  const winzoUSPCompProps: IWinzoUSPComponentProps = {
    winzoUSPData: content.winzoUSP,
  };
  const memberOfCompProps: IMemberOfComponentProps = {
    membersData: commonData.members,
  };
  const winzoStoreOfferCompProps: IWinzoStoreOfferComponentProps = {
    winzoStoreData: content.winzoStore,
  };
  const withdrawalPartnersCompProps: IWithdrawalPartnersComponentProps = {
    withdrawalPartnerData: commonData.withdrawalPartner,
    isGetApp: isGetAppAf || isGetAppRef,
  };
  const winzoVideoCompProps: IWinzoVideoComponentProps = {
    videosData: content.videos,
  };
  const aboutUsCompProps: IAboutUsComponentProps = {
    aboutUsData: content.aboutUs,
  };

  const disclaimerData: IAboutUsComponentProps = {
    ...commonData.disclaimerData,
    isGetApp,
  };
  const downloadAppCompProps: IDownloadAppComponentProps = {
    downloadAppData: content.downloadApp,
  };

  const pageFAQCompProps: IPageFAQComponentProps = {
    pageFAQData: content.pageFAQ,
    showFaqSchema: false,
  };
  const footerCompProps: IFooterComponentProps = {
    // downloadLink: apkUrl,
    footerData: commonData.footer,
    membersData: commonData.members,
    withdrawalPartnerData: commonData.withdrawalPartner,
    pageName: pageName,
  };

  const footerLinksSection: IFooterLinksSectionProps = {
    footerData: commonData.footer,
  };

  const getHowToInstallStoryComponent = () => {
    return showHowToInstallStory(commonData,content) ? (
      <FloatingDownloadButtonComponentWithStory
        {...floatingDownloadBtnCompProps}
        isMute={false}
      />
    ) : <FloatingDownloadButtonComponentV2 {...floatingDownloadBtnCompProps} />
  }

  const getHowToInstallVideoComponent = () => {
    return showHowToInstallVideo(commonData, locale, getQueryStrings().utm) ? (
      <FloatingDownloadButtonComponentWithVideo
        {...floatingDownloadBtnCompProps}
        isMute={isGetAppRef}
      />
    ) : <FloatingDownloadButtonComponentV2 {...floatingDownloadBtnCompProps} />
  }

  return (
    <>
      <Head>
        {(isGetAppRef || isGetApp || isGetAppAf || isGetAppPlay) && (
          <meta name="robots" content="noindex, nofollow" />
        )}
      </Head>
      <AppsFlyer pageName={pageName} variant={props.apiData?.variant} disableOrganicUrl={pageName !== HOME_PAGE_URL} extraParams={{ "af_siteid": howToInstallVariant === VariantA ? HOW_TO_INSTALL_FLOW.VIDEO : HOW_TO_INSTALL_FLOW.STORY }} />
      {/*{isGetApp && getQueryStrings().utm === UTM_TRUECALLER && (*/}
      {/*    <img*/}
      {/*        height={1}*/}
      {/*        width={1}*/}
      {/*        style={{display: "none", position: "absolute"}}*/}
      {/*        src={trueCallerPixelImage}*/}
      {/*        alt="true caller"*/}
      {/*    />*/}
      {/*)}*/}
      {showOfferPopup && <OfferPopupComponent />}
      <ToastComponent {...toastCompProps} />

      {
        isHomePage && [COUNTRY.INDIA, COUNTRY.BRAZIL, COUNTRY.GERMANY].includes(getCountryFromLocale(locale)) ?
          <>
            {howToInstallVariant === VariantA ? getHowToInstallVideoComponent() :
              getHowToInstallStoryComponent()
            }
          </> : getHowToInstallVideoComponent()
      }

      <SideDrawerComponent {...sideDrawerCompProps} />
      <main>
        <HeaderComponentV2 {...headerCompProps} storyFlow={howToInstallVariant !== VariantA && showHowToInstallStory(commonData,content)} />
        <BannerNewComponentV2 {...bannerNewCompProps} />
        <BannerDownloadButtonComponentV2 {...bannerDownloadBtnCompProps} storyFlow={howToInstallVariant !== VariantA && showHowToInstallStory(commonData, content)} />
        <CommonWithdrawalPartnerComponentV2
          {...commonWithdrawalPartnerCompProps}
        />
        <WinzoBannerBottomComponentHomePageV2 {...winzoBannerBottomCompProps} />
        <WinzoUSPComponentV2 {...winzoUSPCompProps} />
        <PopularGamesComponentV2 {...popularGamesCompProps} />
        <WinzoWinnerComponentV2 {...winzoWinnerCompProps} />
        {!isiOSDevice && (
          <div>
            <UserRatingComponentV2 {...userRatingCompProps} />
          </div>
        )}
        {showHowToInstall() && (
          <HowToInstallAppComponentV2 {...howToInstallCompProps} />
        )}
        {commonData.disclaimerData && <AboutUsComponent {...disclaimerData} />}
        {isGetAppAf ? (
          <></>
        ) : (
          <WinzoStoreOfferComponentV2 {...winzoStoreOfferCompProps} />
        )}
        <WithdrawalPartnersComponentV2 {...withdrawalPartnersCompProps} />
        {isHomePage && <MemberOfComponentV2 {...memberOfCompProps} />}
        <WinzoVideoComponentV2 {...winzoVideoCompProps} />
        {isGetAppAf ? <></> : <AboutUsComponentV2 {...aboutUsCompProps} />}
        <DownloadAppComponentV2 {...downloadAppCompProps} />
        {/* <PageFAQComponent {...pageFAQCompProps} /> */}
        <div>
          {isGetAppAf ? <></> : <PageFAQComponentV2 {...pageFAQCompProps} />}
        </div>
        <div>
          {isGetAppAf || (isGetAppRef && getCountryFromLocale(locale) !== COUNTRY.GERMANY) ? (
            <></>
          ) : (
            <FooterLinksSectionV2 {...footerLinksSection} />
          )}
        </div>
        <FooterComponentV2 {...footerCompProps} />
      </main>
    </>
  );
}

export default IndexPageIndiaV2;
