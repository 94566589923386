"use client";

import React, { useRef, useState, useEffect } from "react";
import Analytics from "../../../lib/analytics/Analytics";
import {
  HowToStoryOpen,
  HowToStoryPrevFrame,
  HowToStoryCompleted,
  HowToStoryNextFrame,
  HowToStoryClose,
} from "../../../lib/analytics/AnalyticsEvents";
import {
  useDownloadContext,
  DownloadButtonState,
} from "../../../lib/context/CommonContext";

import {
  IFloatingDownloadButtonComponentProps,
  IFixedDownloadButtonProps,
} from "../../../lib/types/components";
import {
  APK_DOWNLOAD_AGAIN_DELAY,
  FIXED_DOWNLOAD_BUTTON_TYPE,
} from "../../../lib/utils/constants/GlobalConstants";
import {
  checkIsGetAppFb,
  isiOSDevice,
  setIsHowToVideoAvailable,
  isMobile,
} from "../../../lib/utils/constants/PagesConstants";
import { multipleClassName } from "../../../lib/utils/helpers/helper";
import useIsIOS from "../../../lib/utils/helpers/useIsIOS";
import CustomImageComponent from "../../common/CustomImage/CustomImageComponent";
import styles from "./FloatingDownloadButton.module.css";
import FixedDownloadButton from "../FixedDownloadButton/FixedDownloadButton";
import CustomLinkComponent from "../../common/CusotmLink/CustomLinkComponent";

let howToInstallFlowCompleted = false;

const FloatingDownloadButtonWithStory = (
  props: IFloatingDownloadButtonComponentProps
) => {
  const {
    fixedDownloadButtonData,
    pageName,
    isMute = false,
  } = props;

  const videoTag = useRef<any[]>([]);
  const sliderTag = useRef<any[]>([])
  let isIos = useIsIOS();
  const isGetAppFb: boolean = checkIsGetAppFb(pageName);
  const [loadVideo, setLoadVideo] = useState<boolean>(false);
  const [showVideo, setShowVideo] = useState<boolean | null>(null);
  const [currentStoryIndex, setCurrentStoryIndex] = useState<number>(0);
  const [holdState, setHoldState] = useState<boolean>(false);
  const [mute, setMute] = useState<boolean>(isMute)
  const [animationState, setAnimationState] = useState<{
    lastPlayTime: number;
    timeLeft: number;
  }>({
    lastPlayTime: new Date().getTime(),
    timeLeft: (props.howToInstallStory?.stories[currentStoryIndex || 0]?.slideTime || 3) * 1000,
  });
  const { downloadButtonState, setDownloadButtonState } = useDownloadContext();
  const toggleOverflowProperty = () => {
    const html: HTMLHtmlElement | null = document.querySelector("html");
    if (html !== null) {
      html.style.overflowY = showVideo && !isiOSDevice ? "hidden" : "auto";
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined = undefined;
    // if (isGetAppFacebook) {
    if (downloadButtonState === DownloadButtonState.DownloadClicked) {
      if (showVideo === null) {
        setShowVideo(true);
        Analytics.getInstance().logEvent(HowToStoryOpen);
      }
    } else if (downloadButtonState === DownloadButtonState.Downloading) {
      if (!showVideo && showVideo === null) {
        setShowVideo(true);
        Analytics.getInstance().logEvent(HowToStoryOpen);
      }

      timer = setTimeout(() => {
        // setIsApkDownloading(false);
        setDownloadButtonState(DownloadButtonState.DownloadAgain);
      }, APK_DOWNLOAD_AGAIN_DELAY);
    }
    return () => clearTimeout(timer);
  }, [downloadButtonState]);

  // useEffect to call initial function when page is mounted on DOM
  useEffect(() => {
    setIsHowToVideoAvailable(true);
    setLoadVideo(true);
    let newState = {
      ...{
        lastPlayTime: new Date().getTime(),
        timeLeft: (props.howToInstallStory?.stories[currentStoryIndex]?.slideTime || 3) * 1000,
      },
    };
    setAnimationState(newState);
    props.howToInstallStory!.stories?.map(() => {
      videoTag.current.push(React.createRef());
      sliderTag.current.push(React.createRef());
    });
  }, []);

  useEffect(() => {
    isMobile && toggleOverflowProperty();
    if (showVideo && isIos === false) {
      const html: HTMLHtmlElement | null = document.querySelector("html");
      if (html !== null) {
        html.style.scrollBehavior = "initial";
        isMobile &&
          document.getElementById("howToInstallGermany")?.scrollIntoView({
            behavior: "smooth",
          });
      }
      if (videoTag.current[currentStoryIndex].current && sliderTag.current[currentStoryIndex].current) {
        videoTag.current[currentStoryIndex].current.currentTime = 0;
        videoTag.current[currentStoryIndex].current.play();
        sliderTag.current[currentStoryIndex].current.style.animation = `fill ${props.howToInstallStory?.stories[currentStoryIndex].slideTime! || 3}s linear`
      }
    } else {
      videoTag.current[currentStoryIndex].current?.pause();
    }
    return () => {
      videoTag.current[currentStoryIndex].current?.pause();
      const html: HTMLHtmlElement | null = document.querySelector("html");
      if (html !== null) {
        html.style.overflowY = "auto";
      }
    };
  }, [showVideo, videoTag.current, sliderTag.current]);

  const fixedDownloadBtnProps: IFixedDownloadButtonProps = {
    type: FIXED_DOWNLOAD_BUTTON_TYPE,
    fixedDownloadButtonData: fixedDownloadButtonData,
    pageName: pageName,
  };

  useEffect(() => {
    if (showVideo) {
      let interval;
      if (holdState) {
        clearInterval(interval);
        let newState = {
          ...{
            lastPlayTime: animationState.lastPlayTime,
            timeLeft:
            ((props.howToInstallStory?.stories[currentStoryIndex].slideTime! || 3) * 1000) -
              (new Date().getTime() - animationState.lastPlayTime),
          },
        };
        setAnimationState(newState);
      } else {
        interval = setInterval(() => {
          handleNextClick();
        }, animationState.timeLeft);
      }
      return () => {
        clearInterval(interval);
      };
    }
  }, [currentStoryIndex, holdState, showVideo]);

  const getStoryBarClass = (index: number) => {
    if (currentStoryIndex === index) {
      return "active";
    } else if (currentStoryIndex > index) {
      return "complete";
    } else {
      return "";
    }
  };

  const handlePrevClick = () => {
    const newIndex = currentStoryIndex - 1;
    let newState = { lastPlayTime: new Date().getTime(), timeLeft: (props.howToInstallStory?.stories[currentStoryIndex].slideTime! || 3)* 1000 }
    if (newIndex < 0) {
      setCurrentStoryIndex(props.howToInstallStory!.stories.length - 1);
      newState = { lastPlayTime: new Date().getTime(), timeLeft: (props.howToInstallStory?.stories[props.howToInstallStory!.stories.length - 1].slideTime! || 3)* 1000 }
    } else {
      setCurrentStoryIndex(newIndex);
      newState = { lastPlayTime: new Date().getTime(), timeLeft: (props.howToInstallStory?.stories[newIndex].slideTime! || 3)* 1000 }
    }
    setAnimationState(newState);
    videoTag.current[currentStoryIndex].current?.play();
    sliderTag.current[currentStoryIndex].current.style.animation = 'none';
    Analytics.getInstance().logEvent(HowToStoryPrevFrame);
  };

  const handleNextClick = () => {
    const newIndex = currentStoryIndex + 1;
    let newState = { lastPlayTime: new Date().getTime(), timeLeft: (props.howToInstallStory?.stories[0].slideTime! || 3) * 1000 }
    if (newIndex === props.howToInstallStory!.stories.length) {
      setCurrentStoryIndex(0);
      if (!howToInstallFlowCompleted) {
        Analytics.getInstance().logEvent(HowToStoryCompleted);
        howToInstallFlowCompleted = true;
      }
    } else {
      setCurrentStoryIndex(newIndex);
      newState = { lastPlayTime: new Date().getTime(), timeLeft: (props.howToInstallStory?.stories[newIndex].slideTime! || 3) * 1000 }
    }
    setAnimationState(newState);
    videoTag.current[currentStoryIndex].current?.play();
    sliderTag.current[currentStoryIndex].current.style.animation = 'none';
    Analytics.getInstance().logEvent(HowToStoryNextFrame);
  };

  const handleVideoClick = (event) => {
    event.preventDefault();
    if (
      event.clientX >
      window.innerWidth *
        (props.howToInstallStory!.config.previousSlideClickPercentage / 100)
    ) {
      handleNextClick();
    } else {
      handlePrevClick();
    }
  };
  const handleStoryPause = (event) => {
    event.preventDefault();
    setHoldState(true);
    videoTag.current[currentStoryIndex].current?.pause();
    let element = sliderTag.current[currentStoryIndex].current
    element.style.animationPlayState = 'paused'
  };

  const handleStoryPlay = (event) => {
    event.preventDefault();
    setHoldState(false);
    videoTag.current[currentStoryIndex].current?.play();
    let element = sliderTag.current[currentStoryIndex].current
    element.style.animationPlayState = 'running'
  };

  const getStoryStateClass = () => {
    return holdState
      ? "storyAnimationPaused"
      : "storyAnimationRunning";
  };

  useEffect(()=>{
    setMute(mute)
    if(videoTag.current.length && videoTag.current[currentStoryIndex].current){
        videoTag.current[currentStoryIndex].current.currentTime = 0;
        videoTag.current[currentStoryIndex].current.play();
    }
    let element = sliderTag.current[currentStoryIndex].current
    if(element){
        element.style.animation = `fill ${props.howToInstallStory?.stories[currentStoryIndex].slideTime! || 3}s linear`
    }
},[currentStoryIndex])

  return !isGetAppFb ? (
    <div
      className={multipleClassName(
        "floatingDownloadButtonContainer",
        styles.floatingDownloadButtonContainer,
        "floatingDownloadButtonContainerGermany"
      )}
    >
      <div
        className={styles.floatingDownloadButton}
        style={{ display: showVideo && !isiOSDevice ? "block" : "none" }}
      >
        <div style={{ height: "100%" }}>
          <div className={styles.howToInstallLabel}>
            {props.howToInstallStory?.label}
          </div>
          <div
            className={styles.offerPopupClose}
            style={{
              top: "18px",
              right: "10px",
              width: "50px",
              height: "50px",
            }}
            onClick={() => {
              Analytics.getInstance().logEvent(HowToStoryClose);
              setShowVideo(false);
            }}
          >
            <CustomImageComponent
              src={
                "https://d3g4wmezrjkwkg.cloudfront.net/website/images/cross.webp"
              }
              width={50}
              height={50}
              alt="offer-close-popup"
            />
          </div>
          {loadVideo && props.howToInstallStory!.stories?.length != 0 && (
            <div className={styles.storyContainer}>
              <div className={styles.storyBarContainer}>
                {props.howToInstallStory!.stories.map((story, index) => {
                  return (
                    <span
                      className={styles.storyBar}
                      key={index}
                      style={{
                        width: `${
                          100 / props.howToInstallStory!.stories.length
                        }%`,
                      }}
                    >
                      <span
                        className={multipleClassName(
                          getStoryBarClass(index),
                        )}
                        ref={sliderTag.current[index]}
                      ></span>
                    </span>
                  );
                })}
              </div>

              {props.howToInstallStory!.stories?.map((story, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      width: "100%",
                      height: "100%",
                      zIndex: 8,
                      position: "absolute",
                      display: currentStoryIndex === index ? "block" : "none",
                    }}
                    onClick={(event) => {
                      handleVideoClick(event);
                    }}
                    onPointerDown={(event) => {
                      handleStoryPause(event);
                    }}
                    onTouchMove={(event) => {
                      handleStoryPlay(event);
                    }}
                    onPointerUp={(event) => {
                      handleStoryPlay(event);
                    }}
                  >
                    <video
                      poster={story.thumbnail}
                      src={story.url}
                      className={styles.videoView}
                      preload="none"
                      loop={false}
                      ref={videoTag.current[index]}
                      playsInline
                      muted={currentStoryIndex === index ? mute : true}
                      style={{ pointerEvents: "none" }}
                      key={index}
                      controls={false}
                      disablePictureInPicture
                    />
                  </div>
                );
              })}
              <div
                  className={styles.videoSoundControlViewForStory}
                  onClick={() => setMute(!mute)}
              >
                  <CustomImageComponent
                      src={
                          mute
                              ? "https://d3g4wmezrjkwkg.cloudfront.net/website/images/mute.webp"
                              : "https://d3g4wmezrjkwkg.cloudfront.net/website/images/unmute.webp"
                      }
                      width={36}
                      height={36}
                      alt="offer-close-popup"
                  />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={styles.downloadParentContainer}>
        <FixedDownloadButton
          {...fixedDownloadBtnProps}
          storyFlow={true}
          showAnimation={true}
        />
      </div>

      {fixedDownloadButtonData.floatingComponent && (
        <div className={multipleClassName("row", styles.pageBottomContainer)}>
          <div className={styles.imageParentContainer}>
            <div className={styles.imageContainer}>
              <CustomImageComponent
                src={
                  "https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/download_logo.webp"
                }
                alt="logo"
                objectFit={"contain"}
                objectPosition={"center bottom"}
                layout="fill"
              />
            </div>
          </div>

          <div className={multipleClassName("col-4", styles.linksContainer)}>
            <CustomLinkComponent
              href={fixedDownloadButtonData.floatingComponent[0].url}
            >
              <div
                className={styles.floatingParentContainer}
                style={{ marginLeft: "20px" }}
              >
                <div className={styles.floatingImgContainer}>
                  <CustomImageComponent
                    src={fixedDownloadButtonData.floatingComponent[0].img}
                    alt="floating_img"
                    objectFit={"contain"}
                    objectPosition={"center center"}
                    layout="fill"
                  />
                </div>
                <p style={{ margin: 0 }}>
                  {fixedDownloadButtonData.floatingComponent[0].text}
                </p>
              </div>
            </CustomLinkComponent>
          </div>
          <div className={multipleClassName("col-4", styles.linksContainer)}>
            <CustomLinkComponent href="#" />
          </div>
          <div className={multipleClassName("col-4", styles.linksContainer)}>
            <CustomLinkComponent
              href={fixedDownloadButtonData.floatingComponent[1].url}
            >
              <div
                className={styles.floatingParentContainer}
                style={{ marginRight: "20px" }}
              >
                <div className={styles.floatingImgContainer}>
                  <CustomImageComponent
                    src={fixedDownloadButtonData.floatingComponent[1].img}
                    alt="floating_img"
                    objectFit={"contain"}
                    objectPosition={"center center"}
                    layout="fill"
                  />
                </div>
                <p style={{ margin: 0 }}>
                  {fixedDownloadButtonData.floatingComponent[1].text}
                </p>
              </div>
            </CustomLinkComponent>
          </div>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default FloatingDownloadButtonWithStory;
